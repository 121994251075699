import styled from '@emotion/styled';
import { PageProps } from 'gatsby';
import { Form } from 'packages/formidable';
import { UserData } from 'packages/innedit';
import React, { FC } from 'react';
import { SubmissionError } from 'redux-form';

import Button from '~/components/Button';
import Logo from '~/components/Layout/Navigation/Logo';
import register from '~/params/user/register.json';
import { borderRadius, spacing } from '~/styles/theme';
import phoneUtil, { PNF } from '~/utils/phoneUtil';

export const LoginEL = styled.div`
  background: #fff;
  padding: ${spacing[6]};
  margin: 0 auto;
  border-radius: ${borderRadius.s};
  border: 1px solid #e5e7eb;
`;

const RegisterPage: FC<PageProps> = () => {
  const handleOnSubmit = async (values: any) => {
    // const userData = new UserData({ params: register });

    const phoneNumber = phoneUtil.parseAndKeepRawInput(values.phone, 'fr');
    const phone: string = phoneUtil.format(phoneNumber, PNF.E164);

    try {
      await UserData.register({ ...values, phone });
    } catch (error) {
      console.error((error as Error).message);
      throw new SubmissionError({
        _error: (error as Error).message,
      });
    }
  };

  return (
    <div className="flex min-h-main flex-col items-center justify-center">
      <div className="mx-auto w-full max-w-md">
        <div className="mb-12 flex w-full justify-center">
          <Logo width="140px" />
        </div>

        <LoginEL>
          <Form
            bodyProps={{
              className: 'flex flex-col space-y-3 mb-12',
            }}
            datas={register}
            enableReinitialize
            footerProps={{
              className: 'mt-6 flex justify-center',
            }}
            name="user"
            onSubmit={handleOnSubmit}
            submitProps={{
              className: 'w-full',
              label: 'Créer un compte',
            }}
          />
        </LoginEL>
        <div className="mt-1.5 flex justify-center">
          <Button className="text-center" to="/login" variant="link">
            Se connecter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
