import styled from '@emotion/styled';
import React, { FC } from 'react';

import Button from '~/components/Button';

const LogoSVGSC = styled.svg`
  display: inline-block;
`;

interface LogoProps {
  className?: string;
  color?: string;
  height?: string;
  width?: string;
}

const Logo: FC<LogoProps> = ({
  className,
  color,
  height = '100%',
  width = '100%',
}) => (
  <Button
    className={className || 'h-full w-full'}
    color="primary"
    to="/"
    variant="link"
  >
    <LogoSVGSC
      clipRule="evenodd"
      fillRule="evenodd"
      height={height}
      strokeLinejoin="round"
      strokeMiterlimit={2}
      version="1.1"
      viewBox="0 0 222 75"
      width={width}
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(4.16667,0,0,4.16667,0.774495,73.4194)">
        <path
          d="M0,-13.105L0.048,0.103L2.464,0.18L2.401,-9.92L2.536,-13.285L0,-13.105Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,17.5537,18.2106)">
        <path
          d="M0,13.559C0,13.559 0.468,4.679 0.576,3.266C0.734,1.132 2.411,0 4.951,0.078C7.471,0.156 7.927,3.032 7.699,11.205L7.656,13.161L5.123,12.965C5.123,12.965 5.294,9.371 5.323,7.714C5.348,6.3 5.388,3.494 4.092,3.202C2.794,2.913 2.389,4.409 2.336,6.581C2.279,8.933 2.33,13.473 2.33,13.473L0,13.559Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,55.7187,13.661)">
        <path
          d="M0,14.18C0,14.18 -0.341,4.241 0.682,2.378C1.221,1.398 5.744,-0.046 7.217,2.222C8.69,4.492 8.293,14.226 8.293,14.226L5.917,14.073C5.917,14.073 6.098,7.547 5.797,5.832C5.449,3.825 2.332,3.471 2.308,5.262C2.284,7.262 2.154,14.072 2.154,14.072L0,14.18Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,111.173,64.3423)">
        <path
          d="M0,-9.714C1.498,-9.974 1.925,-6.421 1.925,-6.421C1.925,-6.421 -1.436,-5.305 -1.572,-6.013C-1.708,-6.725 -1.497,-9.455 0,-9.714M4.726,-2.905L2.466,-2.76C2.466,-2.76 3.3,-0.339 2.055,0.154C0.812,0.648 -1.479,0.391 -1.491,-0.56C-1.507,-1.51 -1.483,-3.596 -1.483,-3.596L3.988,-4.255L3.793,-6.923C3.793,-6.923 3.868,-10.362 1.98,-11.167C0.094,-11.969 -4.151,-11.433 -3.707,-6.575C-3.261,-1.722 -4.092,1.376 -1.709,1.815C0.675,2.255 3.168,2.228 4.029,1.387C4.89,0.541 4.726,-2.905 4.726,-2.905"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,160.859,13.5977)">
        <path
          d="M0,11.271C-0.309,12.848 -3.045,12.243 -3.904,12.262C-4.766,12.282 -4.377,5.549 -4.029,4.646C-3.683,3.74 -0.868,3.418 -0.868,3.418C0.311,3.993 0.409,9.182 0,11.271M1.835,-3.193L-0.289,-3.143L-0.441,1.136C-0.441,1.136 -3.039,1.423 -4.154,1.591C-5.743,1.825 -5.89,3.535 -5.851,5.139C-5.814,6.743 -6.101,10.626 -6.072,11.959C-6.022,14.063 -4.641,14.303 -2.263,14.38C0.116,14.458 1.244,14.464 1.943,12.306C2.645,10.154 2.166,5.756 2.235,3.214C2.299,0.671 1.835,-3.193 1.835,-3.193"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,178.61,73.1927)">
        <path
          d="M0,-13.313C0,-13.313 -0.201,-4.55 -0.21,-3.808C-0.218,-3.069 -0.35,-0.009 -0.35,-0.009L2.199,0C2.199,0 2.406,-3.039 2.343,-5.572C2.281,-8.11 2.627,-13.28 2.627,-13.28L0,-13.313Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,198.299,72.8365)">
        <path
          d="M0,-16.041C0,-16.041 -0.491,-6.763 -0.512,-4.885C-0.536,-3.01 -1.321,-0.006 1.678,0.125C4.678,0.256 5.617,0.079 5.617,0.079L5.687,-1.975C5.687,-1.975 3.016,-1.828 2.079,-1.935C1.142,-2.04 1.742,-5.139 1.75,-5.796L4.096,-5.86L4.214,-7.828L1.778,-8.048L2.066,-16.297L0,-16.041Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,0.0728282,7.57521)">
        <path
          d="M0,-0.277C0.186,-1.581 2.367,-1.774 2.799,-0.747C3.232,0.282 2.909,1.308 1.616,1.405C0.323,1.497 -0.109,0.469 0,-0.277"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(4.16667,0,0,4.16667,180.405,0.920625)">
        <path
          d="M0,2.549C-0.862,2.226 -0.892,0.64 0,0.126C0.892,-0.385 2.425,0 2.296,1.146C2.169,2.295 1.021,2.934 0,2.549"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </LogoSVGSC>
  </Button>
);

export default Logo;
